const Pending = () => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center vh-100">
        <div className="col-md-5">
          <div className="card">
            <div className="card-body text-center">
              <h4 style={{ color: "#D97706" }}>Oops!</h4>
              <p style={{ color: "#3c3c3c" }}>
                You have not yet been approved to be an influencer. Kindly check
                back later or contact support
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pending
