import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPassword from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard"

// Referrals
import Transactions from "../pages/Transactions/Transactions"
import TransactionDetails from "../pages/Transactions/TransactionDetails"
import Profile from "../pages/profile/Profile"
import Pending from "../pages/pending/Pending"
import Referrals from "../pages/referrals/Referrals"

// Transactions

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/referrals", component: Referrals },
  { path: "/transactions", component: Transactions },
  { path: "/transactions/:id", component: TransactionDetails },
  { path: "/profile", component: Profile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/pending", component: Pending },
]

export { userRoutes, authRoutes }
