// import logo from "../../assets/images/logo.png";
// import store from "../../assets/images/store.svg";
import { FaGoogle } from "react-icons/fa"

import { useLocation } from "react-router-dom"
import { signInWithPopup } from "firebase/auth"
import { auth, provider } from "../firebaseConfig"
import { useEffect, useState } from "react"
// import "./home.css";

const GoogleLogin = () => {
  const [deviceType, setDeviceType] = useState("")
  const [deviceVersion, setDeviceVersion] = useState("")
  const [fcmToken, setfcmToken] = useState("")
  const [loading, setLoading] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [error, setError] = useState("")

  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    setDeviceType(queryParams.get("device_type"))
    setDeviceVersion(queryParams.get("device_version"))
    setfcmToken(queryParams.get("fcm_token"))
  }, [deviceType, deviceVersion, fcmToken, location.search])

  const handleSubmit = () => {
     setLoading(true)
    setError("")
    setDisableButton(true)
    signInWithPopup(auth, provider).then(data => {
      let provider = data.user.providerData[0].providerId.split(".")[0]
      let providerId = data.user.providerData[0].uid
      getUserDetails(provider, providerId)
    }) 
  }

  // Get user details
  const getUserDetails = (provider, providerId) => {
    const url = process.env.REACT_APP_BASEURL
    fetch(`${url}/api/v1/users/social-login-data/${provider}/${providerId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "error") {
          setError(response.message)
          setLoading(false)
        } else {
          let firstName = response.data.first_name
          let lastName = response.data.last_name
          let email = response.data.email
          let fcmToken = response.data.fcm_token
          let deviceType = response.data.device_type
          let deviceVersion = response.data.device_version
          let provider = response.data.social_login.provider
          let providerId = response.data.social_login.provider_id
          handleLogin(
            firstName,
            lastName,
            email,
            fcmToken,
            deviceType,
            deviceVersion,
            provider,
            providerId
          )
        }
      })
      .catch(error => {
        // Handle any errors here
        console.log(error)
        setLoading(false)
        setDisableButton(false)
        setError("Account does not exist, you need to create an account")
      })
  }

  // Handle Login
  const handleLogin = (
    firstName,
    lastName,
    email,
    fcmToken,
    deviceType,
    deviceVersion,
    provider,
    providerId
  ) => {
    const url = process.env.REACT_APP_BASEURL
    fetch(`${url}/api/v1/users/social-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        provider,
        provider_id: providerId,
        device_type: deviceType,
        device_version: deviceVersion,
        fcm_token: fcmToken,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.data.user.is_influencer === true) {
          localStorage.setItem("authUser", response.data.token)
          localStorage.setItem("userId", response.data.user.uuid)
          window.location.href = `/dashboard`
          // console.log(response.data.token, response.data.user.uuid)
        } else {
          window.location.href = `/pending`
          // console.log(response.data.user.is_influencer)
          // console.log(response.data.user)
        }
      })
      .catch(error => {
        // Handle any errors here
        console.log(error)
        setLoading(false)
        setDisableButton(false)
        setError("Account does not exist, you need to create an account")
      })
  }

  return (
    <button
      onClick={handleSubmit}
      className="social-btn p-3 w-100"
      disabled={disableButton}
    >
      {loading ? (
        <div
          className="spinner-border text-dark"
          role="status"
          style={{
            fontSize: "10px",
            width: "25px",
            height: "25px",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          <span className="mx-2 h5">
            <FaGoogle />
          </span>
          Continue with Google
        </>
      )}
    </button>
  )
}

export default GoogleLogin
