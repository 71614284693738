import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody } from "reactstrap";

import accessToken, { uuid } from "../../helpers/jwt-token-access/accessToken";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/users/${uuid}`,
          options
        );
        const user = await response.json();
        console.log(user.data);
        setUser(user.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      =
      <div className="page-content">
        <MetaTags>
          <title>Profile | Influencer Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Profile</h4>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-10 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="details-row mx-0 mb-4">
                      <div className="row">
                        <div className="col-md-4">
                          <h6 className="details-name">Full Name</h6>
                          <h4 className="details-info">
                            {user?.first_name} {user?.last_name}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">Email Address</h6>
                          <h4 className="details-info">{user?.email}</h4>
                        </div>
                        <div className="col-md-4">
                          <h6 className="details-name">Phone Number</h6>
                          <h4 className="details-info">{user?.phone}</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
