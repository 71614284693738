import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Table, Row, Col, Card, CardBody } from "reactstrap";
import "./Users.css";

import "./datatables.scss";
import accessToken, { uuid } from "../../helpers/jwt-token-access/accessToken";

import ReactPaginate from "react-paginate";
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

import emptyState from "../../assets/images/empty-state.png";

const Referrals = () => {
  const [users, setUsers] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(1);
  const [lastPage, setLastPage] = useState({});
  const [sort, setSort] = useState("desc");
  const [perPage, setPerPage] = useState(50);
  const [activeTab, setActiveTab] = useState("Tab1");
  const [activeTable, setActiveTable] = useState("Tab1");
  const [showDate, setShowDate] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -365),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const minDate = dateRange[0].startDate.toISOString().split("T")[0];
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0];

  const handlePageClick = (data) => {
    setNext(data.selected + 1);
  };

  // user referrals
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/users/${uuid}/referrals?perPage=${perPage}&page=${next}&sort=${sort}&date_range=${minDate},${maxDate}`,
          options
        );
        const users = await response.json();
        console.log(users);
        console.log(users.data);
        setUsers(users.data.users);
        setLoading(false);
        setLastPage(users.data.meta);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    };

    fetchData();
  }, [next, sort, perPage, minDate, maxDate]);

  // Fetch hub referrals
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/users/${uuid}/hub-referrals?perPage=${perPage}&page=${next}&sort=${sort}&date_range=${minDate},${maxDate}`,
          options
        );
        const hubs = await response.json();
        console.log(hubs);
        console.log(hubs.data.hubs);
        setHubs(hubs.data.hubs);
        setLoading(false);
        setLastPage(hubs.data.meta);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    };

    fetchData();
  }, [next, sort, perPage, minDate, maxDate]);

  return (
    <React.Fragment>
      =
      <div className="page-content">
        <MetaTags>
          <title>Referrals | Influencer Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Referrals</h4>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-10 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex gap-3 mb-5">
                        <button
                          onClick={() => {
                            setActiveTab("Tab1");
                            setActiveTable(true);
                          }}
                          className={`tab-btn ${
                            activeTab === "Tab1" && "active-tab"
                          }`}
                        >
                          User Referrals
                        </button>
                        <button
                          onClick={() => {
                            setActiveTab("Tab2");
                            setActiveTable(false);
                          }}
                          className={`tab-btn ${
                            activeTab === "Tab2" && "active-tab"
                          }`}
                        >
                          Hub Referrals
                        </button>
                      </div>
                      {activeTable ? (
                        <div>
                          {users?.length === 0 ? (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <img
                                src={emptyState}
                                alt="empty state"
                                width={206}
                                height={206}
                              />
                              <p
                                style={{
                                  maxWidth: "185px",
                                  textAlign: "center",
                                  color: "#8D8D8D",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "21px",
                                }}
                              >
                                No results found
                              </p>
                            </div>
                          ) : (
                            <>
                              <Row className="mt-2">
                                <Col md={8}>
                                  <h4 className="title-header">
                                    User Referrals
                                  </h4>
                                </Col>
                                <Col md={1} xs={4}>
                                  <select
                                    className="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    value={perPage}
                                    onChange={(e) => {
                                      setLoading(true);
                                      setPerPage(e.target.value);
                                    }}
                                  >
                                    <option defaultValue>50</option>
                                    <option defaultValue="25">25</option>
                                    <option defaultValue="10">10</option>
                                    <option defaultValue="5">5</option>
                                  </select>
                                </Col>
                                <Col md={3} xs={8}>
                                  <Row className="">
                                    <Col>
                                      <FaArrowUp
                                        className="sort"
                                        onClick={() => setSort("asc")}
                                      />{" "}
                                      <FaArrowDown
                                        className="sort"
                                        onClick={() => setSort("desc")}
                                      />{" "}
                                      Sort
                                    </Col>
                                    <Col
                                      className="sort"
                                      onClick={() => setShowDate(!showDate)}
                                    >
                                      <FaFilter /> Filter
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  {showDate && (
                                    <DateRangePicker
                                      onChange={(item) =>
                                        setDateRange([item.selection])
                                      }
                                      showSelectionPreview={true}
                                      moveRangeOnFirstSelection={false}
                                      months={1}
                                      ranges={dateRange}
                                      direction="horizontal"
                                    />
                                  )}
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <Table className="table responsive table-hover mt-2 mb-0">
                                  <thead>
                                    <tr>
                                      <th>REFERRAL NAME</th>
                                      <th>EMAIL ADDRESS</th>
                                      <th>PHONE NUMBER</th>
                                      <th>DATE REGISTERED</th>
                                      <th>COUNT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {users?.map((user) => {
                                      const {
                                        uuid,
                                        full_name,
                                        email,
                                        created_at,
                                        phone,
                                        completed_order_count,
                                      } = user;
                                      return (
                                        <tr key={uuid}>
                                          <td>{full_name}</td>
                                          <td>{email}</td>
                                          <td>{phone}</td>
                                          <td>{created_at}</td>
                                          <td>{completed_order_count}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                              <div className="float-end mt-3">
                                <ReactPaginate
                                  previousLabel={"Prev"}
                                  nextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={Math.ceil(
                                    lastPage?.total / lastPage?.per_page
                                  )}
                                  marginPagesDisplayed={4}
                                  pageRangeDisplayed={6}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination"}
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div>
                          {hubs?.length === 0 ? (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <img
                                src={emptyState}
                                alt="empty state"
                                width={206}
                                height={206}
                              />
                              <p
                                style={{
                                  maxWidth: "185px",
                                  textAlign: "center",
                                  color: "#8D8D8D",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "21px",
                                }}
                              >
                                No results found
                              </p>
                            </div>
                          ) : (
                            <>
                              <Row className="mt-2">
                                <Col md={8}>
                                  <h4 className="title-header">
                                    Hubs Referrals
                                  </h4>
                                </Col>
                                <Col md={1} xs={4}>
                                  <select
                                    className="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    value={perPage}
                                    onChange={(e) => {
                                      setLoading(true);
                                      setPerPage(e.target.value);
                                    }}
                                  >
                                    <option defaultValue>50</option>
                                    <option defaultValue="25">25</option>
                                    <option defaultValue="10">10</option>
                                    <option defaultValue="5">5</option>
                                  </select>
                                </Col>
                                <Col md={3} xs={8}>
                                  <Row className="">
                                    <Col>
                                      <FaArrowUp
                                        className="sort"
                                        onClick={() => setSort("asc")}
                                      />{" "}
                                      <FaArrowDown
                                        className="sort"
                                        onClick={() => setSort("desc")}
                                      />{" "}
                                      Sort
                                    </Col>
                                    <Col
                                      className="sort"
                                      onClick={() => setShowDate(!showDate)}
                                    >
                                      <FaFilter /> Filter
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  {showDate && (
                                    <DateRangePicker
                                      onChange={(item) =>
                                        setDateRange([item.selection])
                                      }
                                      showSelectionPreview={true}
                                      moveRangeOnFirstSelection={false}
                                      months={1}
                                      ranges={dateRange}
                                      direction="horizontal"
                                    />
                                  )}
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <Table className="table-hover mt-2 mb-0">
                                  <thead>
                                    <tr>
                                      <th>REFERRAL NAME</th>
                                      <th>EMAIL ADDRESS</th>
                                      <th>PHONE NUMBER</th>
                                      <th>DATE REGISTERED</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {hubs?.map((user) => {
                                      const {
                                        uuid,
                                        name,
                                        email,
                                        created_at,
                                        phone,
                                      } = user;
                                      return (
                                        <tr key={uuid}>
                                          <td>{name}</td>
                                          <td>{email}</td>
                                          <td>{phone}</td>
                                          <td>{created_at}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                              <div className="float-end mt-3">
                                <ReactPaginate
                                  previousLabel={"Prev"}
                                  nextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={Math.ceil(
                                    lastPage?.total / lastPage?.per_page
                                  )}
                                  marginPagesDisplayed={4}
                                  pageRangeDisplayed={6}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination"}
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Referrals;
