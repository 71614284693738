import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Link, Redirect } from "react-router-dom";
import { Col, Container, Row, Card, CardBody, Table } from "reactstrap";
import accessToken, { uuid } from "../../helpers/jwt-token-access/accessToken";
import "./index.css";
import { toast, ToastContainer } from "react-toastify";
import { FaRegCopy } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";

import { CopyToClipboard } from "react-copy-to-clipboard";

const Dashboard = () => {
  const [menu, setMenu] = useState(false);
  const toggle = () => {
    setMenu(!menu);
  };
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [total, setTotal] = useState("");
  const [users, setUsers] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionCount, setTransactionCount] = useState("");
  const [redirect, setRedirect] = useState("");
  const [copied, setCopied] = useState(false);

  const getprofile = async () => {
    try {
      const options = {
        method: "GET",
        headers: new Headers({ Authorization: accessToken }),
      };
      const baseURL = process.env.REACT_APP_BASEURL;
      const response = await fetch(`${baseURL}/api/v1/users/${uuid}`, options);
      const profile = await response.json();
      setProfile(profile.data);
      setLoading(false);
      if (response.status === 401 || response.status === 403) {
        setRedirect("/login");
      }
    } catch (error) {
      // do what
      console.log(error, "error");
      toast.error(error);
      setLoading(false);
      setRedirect("/login");
    }
  };

  useEffect(() => {
    getprofile();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/users/${uuid}/referrals`,
          options
        );
        const users = await response.json();
        setUsers(users.data.users.length);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    };

    fetchData();
  }, [uuid]);

  // transactions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/users/${uuid}/transactions?influencer_transactions=1`,
          options
        );
        const transactions = await response.json();
        setTransactions(transactions.data.transactions);
        setTransactionCount(transactions.data.transactions.length);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    };

    fetchData();
  }, []);

  // Fetch hub referrals
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        };
        const baseURL = process.env.REACT_APP_BASEURL;
        const response = await fetch(
          `${baseURL}/api/v1/users/${uuid}/hub-referrals`,
          options
        );
        const hubs = await response.json();
        setHubs(hubs.data.hubs.length);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    };

    fetchData();
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dilivva | Influencer Dashboard</title>
        </MetaTags>
        <Container fluid>
          <ToastContainer />
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={6}>
                <h2 className="dashboard-head">Dashboard Home</h2>
              </Col>
            </Row>
            {loading ? (
              <>
                <Row className="mt-3">
                  <Col md={8}>
                    <Row className="mt-4">
                      <Col md={6}>
                        <Card className="card-height">
                          <CardBody>
                            <div className="col-md-10 skeleton skeleton-p mt-2 mb-4"></div>
                            <div className="col-md-4 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6}>
                        <Card className="card-height">
                          <CardBody>
                            <div className="col-md-10 skeleton skeleton-p mt-2 mb-4"></div>
                            <div className="col-md-4 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={12}>
                            <Card className="card-height">
                              <CardBody>
                                <div className="col-md-5 skeleton skeleton-p mt-2 mb-4"></div>
                                <div className="col-md-3 skeleton skeleton-head p-2 mt-2 mb-4"></div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4} className="mt-4">
                    <Card className="card-height">
                      <Link to="#">
                        <CardBody style={{ minHeight: "308px" }}>
                          <div className="col-md-6">
                            <div className="col-md-5 skeleton skeleton-head p-2 mt-2"></div>
                          </div>
                          <div className="col-md-6">
                            <div className="skeleton skeleton-head p-2 mt-2"></div>
                            <div className="skeleton skeleton-head p-2 mt-2"></div>
                          </div>
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col md={4}>
                        <Link to="/referrals">
                          <Card className="card-height">
                            <CardBody>
                              <p className="head">
                                User Referrals{" "}
                                <span className="user-number">
                                  {profile?.user_count}
                                </span>
                              </p>
                              <h4 className="dashboard-head">{users}</h4>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      <Col md={4}>
                        <Link to="/referrals">
                          <Card className="card-height">
                            <CardBody>
                              <p className="head">Hub Referrals </p>
                              <h4 className="dashboard-head">{hubs}</h4>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      <Col md={4}>
                        <Link to="/transactions">
                          <Card className="card-height">
                            <CardBody>
                              <p className="head">Transactions</p>
                              <h4 className="dashboard-head">
                                {transactionCount}
                              </h4>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody
                            style={{
                              minHeight: "310px",
                            }}
                          >
                            {loading ? (
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                  <div className="col-md-12 skeleton skeleton-search"></div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {transactions.length > 0 ? (
                                  <>
                                    <Row className="mt-2">
                                      <Col md={9}>
                                        <h4 className="title-header">
                                          Transactions
                                        </h4>
                                      </Col>
                                    </Row>

                                    <div className="table-responsive">
                                      <Table className="table responsive table-hover mt-2 mb-0">
                                        <thead>
                                          <tr>
                                            <th>Amount</th>
                                            <th>Reference</th>
                                            <th>Type</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {transactions
                                            ?.slice(0, 2)
                                            ?.map((transaction) => {
                                              const {
                                                uuid,
                                                amount,
                                                created_at,
                                                status,
                                                type,
                                                reference,
                                              } = transaction;
                                              return (
                                                <tr
                                                  key={uuid}
                                                  onClick={() =>
                                                    setRedirect(
                                                      "/transactions/" + uuid
                                                    )
                                                  }
                                                  className="table-row"
                                                >
                                                  <td>₦ {amount}</td>
                                                  <td>{reference}</td>
                                                  <td
                                                    style={{
                                                      color:
                                                        type === "credit"
                                                          ? "#29CC97"
                                                          : type === "debit"
                                                          ? "#DC1515"
                                                          : "#FEC400",
                                                    }}
                                                  >
                                                    {type}
                                                  </td>
                                                  <td>{created_at}</td>
                                                  <td
                                                    style={{
                                                      color:
                                                        status === "success"
                                                          ? "#29CC97"
                                                          : status === "pending"
                                                          ? "#FEC400"
                                                          : "#DC1515",
                                                    }}
                                                  >
                                                    {status}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    <h5>No Transactions</h5>
                                  </div>
                                )}
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Card className="card-height">
                      <CardBody style={{ minHeight: "470px" }}>
                        <h5 className="dashboard-head">Summary</h5>
                        <div className="profile-wallet p-3">
                          <p className="mb-0 pt-3">Balance</p>
                          <h2 className="dashboard-head h1 fw-bold">
                            ₦ {profile?.wallet?.influencer_balance}
                          </h2>
                        </div>
                        <div>
                          <h5 className="my-3">Referral Link</h5>
                          <p>{profile?.referral_link}</p>
                          {copied ? (
                            <span className="text-success mx-4">
                              <BsCheckLg />
                            </span>
                          ) : (
                            <CopyToClipboard
                              text={profile?.referral_link}
                              onCopy={() => {
                                setCopied(true);
                                setTimeout(() => {
                                  setCopied(false);
                                }, 1500);
                              }}
                            >
                              <span
                                style={{
                                  background: "#f7f7f7",
                                  margin: "0 10px",
                                  padding: "10px",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                  color: "#444",
                                }}
                              >
                                <FaRegCopy
                                  size={20}
                                  className="text-secondary"
                                />
                              </span>
                            </CopyToClipboard>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
