import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"

import accessToken, { uuid } from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import profileImage from "../../assets/images/profile-img.png"

const TransactionDetails = props => {
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)

  // Transaction Details
  useEffect(() => {
    console.log("first")
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/v1/users/${uuid}/transactions/${props?.match?.params?.id}`,
          options
        )
        const transactions = await response.json()
        console.log(transactions.data, "transaction")
        setLoading(false)
        setTransactions(transactions.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Transaction Details | Influencer Admin</title>
        </MetaTags>
        <h4 className="transactions-head mb-4">Transaction Details</h4>
        <ToastContainer />
        {loading ? (
          <>
            <Row>
              <Col lg={4}>
                <Row>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-3 skeleton skeleton-p my-2 mb-4"></div>
                              <div className="col-md-8 skeleton skeleton-head p-3 "></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <div className="container">
                <div className="row p-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="row p-2">
                        <div className="col-md-3">
                          <div className="col-md-4 skeleton skeleton-p p-1"></div>
                          <div className="col-md-12 skeleton skeleton-p p-2"></div>
                        </div>
                        <div className="col-md-5">
                          <div className="col-md-4 skeleton skeleton-p p-1"></div>
                          <div className="col-md-11 skeleton skeleton-p p-2"></div>
                        </div>
                        <div className="col-md-1">
                          <div className="col-md-9 skeleton skeleton-p p-1"></div>
                          <div className="col-md-12 skeleton skeleton-p p-2"></div>
                        </div>
                        <div className="col-md-1">
                          <div className="col-md-9 skeleton skeleton-p p-1"></div>
                          <div className="col-md-12 skeleton skeleton-p p-2"></div>
                        </div>
                        <div className="col-md-2">
                          <div className="col-md-8 skeleton skeleton-p p-1"></div>
                          <div className="col-md-7 skeleton skeleton-p p-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={4}>
                <Card className="profile-wallet">
                  <CardBody>
                    <Col md={12}>
                      <Row className="p-2">
                        <Col>
                          <p className="wallet">Amount</p>
                          <h2 className="balance">₦ {transactions?.amount}</h2>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="container">
              <div className="row">
                <div className="card">
                  <div className="card-body">
                    <div className="row p-2">
                      <div className="col-md-3">
                        <span className="price-head text-green">Reference</span>
                        <h6 className="details">{transactions?.reference}</h6>
                      </div>

                      <div className="col-md-5">
                        <span className="price-head text-green">
                          Description
                        </span>
                        <h6 className="details">{transactions?.description}</h6>
                      </div>
                      <div className="col-md-1">
                        <span className="price-head">Type</span>
                        <h6
                          className="details"
                          style={{
                            color:
                              transactions?.type === "credit"
                                ? "#29CC97"
                                : transactions?.type === "debit"
                                ? "#DC1515"
                                : "#FEC400",
                          }}
                        >
                          {transactions?.type}
                        </h6>
                      </div>
                      <div className="col-md-1">
                        <span className="price-head">Status</span>
                        <h6
                          className="details"
                          style={{
                            color:
                              transactions?.status === "success"
                                ? "#29CC97"
                                : transactions?.status === "pending"
                                ? "#FEC400"
                                : "#DC1515",
                          }}
                        >
                          {transactions?.status}
                        </h6>
                      </div>
                      <div className="col-md-2">
                        <span className="price-head text-green">
                          Date Updated
                        </span>
                        <h6 className="details">{transactions?.updated_at}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default TransactionDetails
