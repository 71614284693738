import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";

// import images
import logoSm from "../../assets/images/dilivva-logo.webp";

const url = window.location.origin;

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisbaleButton(true);
    setButtonLoad(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ email }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    const response = fetch(`${baseURL}/api/v1/forgot-password`, options)
      .then((data) => data.json())
      .then((response) => {
        setButtonLoad(false);
        toast(response.message);
        console.log(response.data.token);
        console.log(response.data);
        localStorage.setItem("authUser", response.data.token);
        setTimeout(() => {
          window.location = `${url}/login`;
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Dilivva Admin Dashboard</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <ToastContainer />
          <Row className="justify-content-center">
            <Col md={6} lg={5} xl={4}>
              <Card className="overflow-hidden">
                <div className="text-primary text-center p-4">
                  <Link to="/" className="logo logo-admin">
                    <img src={logoSm} height="50" alt="logo" />
                  </Link>
                </div>
                <CardBody className="m-3">
                  <div className="login-head">
                    <h2 className="mb-4">Dilivva</h2>
                    <p>Enter your email to reset password below</p>
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <Label className="input-label mt-4">EMAIL</Label>
                    <Input
                      type="text"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                      required
                    />
                    <Row className="mt-4 mb-5">
                      <Col sm={12} className="text-end">
                        <button
                          className="login-btn w-100"
                          disabled={disableButton}
                          type="submit"
                        >
                          {buttonLoad ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                class="spinner-border text-white"
                                role="status"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            "Reset Password"
                          )}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
